@import "variables.scss";

.dropdown {  position: relative; display: inline-block;
  .dropdown-content{ display: block; visibility: hidden; width: 220px; position: absolute; background-color: #f1f1f1; min-width: 160px; 
  }  
  &:hover{ 
    animation: 1s ease fade-in; z-index: 20;
    .dropdown-content{ z-index: 10; visibility: visible; background: white; color: black; padding: 12px 16px; text-decoration: none; display: block; 
                      border-radius: 0 0 10px 10px; border-right: 1px solid #D8325C; border-bottom: 1px solid #D8325C; border-left: 1px solid #D8325C;
      
        .drop-item { padding: 1em 0; cursor: pointer; 
        &:hover {font-weight: bold; }
      }
    }
    button { background: white; color: black; border-radius: 10px 10px 0 0; border-right: 1px solid #D8325C; border-top: 1px solid #D8325C; 
            border-left: 1px solid #D8325C; height: 40px;}
  }
  button { width: 220px; height: 40px;
    img {width: 20px; position: absolute; top: 10px; right: 30px;}
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}

