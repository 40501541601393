#coming-soon{width:100%; max-width: 1280px; margin: 1em auto;display: flex; flex-direction: row;
    > .card{width: 60%;}
    .col{width: 39%;}
    .col{margin-left: auto;
        .card{
            &:first-child{margin: 0 0 1.5em 0;}
            img{display: block; width: 100%; height: auto;}
        }
    }
    > .card{
        .items{padding: 2em 1em;
            .item{margin: 0 0 3em 0; padding: 0 0 3em 0; border-bottom: 1px solid lightgray;}
        }
    }
}

@media screen and (max-width: 768px) {
    #coming-soon{display: block;
        > .card, .col{width: 96%; margin: 1em auto;}
    }
}