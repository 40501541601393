@import "variables.scss";

#footer{display: flex; padding: 16px 24px; background: #003350; align-items: center; height: 105px; margin-top: -105px;
    .logo{ flex-grow: 1;
        &.logo-left{
            img{height: 40px;}
        }
        &.logo-right{margin: 0 0 0 auto; text-align: right;
            img{height: 35px;}
        }
        img{display: inline-block; width: auto;}
    }
    .center-block{text-align: center; flex-grow: 2;
        button{display: block; margin: 0 auto; width: 100%; max-width:200px; border:1px solid #fff; background: none; text-align: left; padding: 5px 16px;
            a{color: #fff; font-weight: normal; display: flex; align-items: center;
                i{margin: 0 0 0 auto;}
            }
            &:hover{background: #fff;
                a{color:#003350;}
            }
        }
        ul{display: block; margin: 8px auto;
            li{color: #fff; display: inline-block;
                a{color: inherit;}
                &:after{content:"|"; padding: 0 8px;}
                &:last-child:after{display: none;}
            }
        }
    }
}