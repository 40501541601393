.dashboardtable-container{ background: white; border: 1px solid #D8325C; border-radius: 10px; height: 400px;
                          margin: 2em 2em; overflow: hidden;
                          .grid-header{ display: flex; position: fixed; text-align: center; height: 60px; background: white;
                            padding: 20px 0; border-radius: 10px 10px 0 0; border-bottom: 1px solid lightgrey; border-radius: 10px 10px 0 0;
                            div {flex-basis: 33%; text-align: center;}
                          }
                          .spacer {height: 60px;}
                          .grid-content-container{ overflow-y: auto; height: 338px;
                            .grid-content{
                              .dashboard-grid{display: grid; grid-template-columns: 34% 33% 33%; text-align: center; 
                                div { padding: 20px 0; border-right: 1px solid lightgrey; }
                                .clock-image {width: 20px;}
                              }
                            }
                            .grid-content-placeholder { display: grid; grid-template-columns: 34% 33% 33%; text-align: center;
                              div { border-right: 1px solid lightgrey;}
                            }
                          }
                          
}

@media screen and (max-width: 1000px) {
  .grid-header {
    width: 89.7%;
   }
}

@media screen and (min-width: 1000px) {
  .grid-header {
    width: 90.3%;
   }
}

