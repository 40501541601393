// $base-color: #c6538c;
// $border-dark: rgba($base-color, 0.88);

// .alert {
//   border: 1px solid $border-dark;
// }

$primary: #435d7b;
$secondary: #d8325c;
$grey: #f3f7fa;
$pastelBlue:#5C6FAB;
$pastelPink:#ff7e9f;
$bordasGreen: #049140;
$bordasLightGreen: #dad944;
$fontColor: #333