@import 'variables.scss';

#educationalSpace{background: white; padding: 2%; position: relative;
    a.btn, button.btn{width: 100%; max-width:225px; text-align: center; border: 1px solid $secondary;
        &:hover{color: $secondary; background: white; }
        &.disabled{background: #ccc;color:#444;border-color:#ccc;font-style:italic;
            &:hover{background: #ccc;color:#444;border-color:#ccc;}
        }
    }
    p{line-height: 16px;}
    img{display: block; width: auto; height: auto; margin: 0 auto;}
    nav{width: 100%; position: fixed; top: 50px; left:0; z-index: 100;
        > div{
            .ant-anchor-wrapper{
                .ant-anchor{display: flex; justify-content: center; align-items: center; height: 65px; background: white; 
                    .ant-anchor-ink{display: none;}
                    .ant-anchor-link{
                        &:before{display: none;}
                        span.ant-anchor-ink-ball{display: none;}
                        &.ant-anchor-link-active{
                            a{border-color: $secondary;}
                            a, a:hover{ color: $fontColor;}
                            
                        }
                        a, a:hover{ color: $fontColor;}
                        a{border-bottom: 2px solid #eee; padding: 0 0 1em 0;
                            &:hover{border-color: $secondary;}
                        }
                    }
                }
            }
        }
    }
    .anchors{ width: 100%; height: 1px; position: absolute; 
        &#anchor1{top: -75px; }
        &#anchor2{top: -150px; }
        &#anchor3{top: -150px; }
        &#anchor4{top: -150px; }

    }
    section{ width: 100%; max-width: 1150px; min-width: 700px; margin: 0 auto 30px auto; padding: 0 0 30px 0; border-bottom:2px solid #eee; position: relative; z-index: 1;
        h1{text-align: center; margin: 0 0 40px 0;}
        h2{}
        .italic{font-style: italic;}
        &#section1{padding-top: 50px;
            .container{
                &:nth-child(3){display: flex; margin: 0 0 55px 0;
                    .block{width: 50%;
                        &:first-child{
                            .links{display: flex; margin: 55px 0 0 0; justify-content: center;
                                a, button{
                                    &:last-child{margin-left: 2%;}
                                }
                            }
                        }
                        &:nth-child(2){margin: 0 0 0 2%;
                            p{margin: 28px 0 0 0; text-align: justify;}
                        }
                        video{width: 100%;}
                        .video_title{display: block; font-weight: 600; text-align: center;}
                    }
                }
                &:nth-child(4){text-align: center;margin: 0 0 55px 0;
                    img{max-width: 800px; width: 100%;}
                }
                &:nth-child(5){display: flex; justify-content: center;
                    .block{width: 100%; max-width: 370px; border: 2px solid $secondary; border-radius: 10px;
                        img{width: auto; padding: 1em 0 0 0;}
                        p{padding: 1em 2em; text-align: justify;}
                        &:first-child{margin-right: 5%;}
                    }
                }
            } 
        }
        &#section2{
            img{max-width: 838px; width: 100%;}
        }
        &#section3{
            p{margin:0 0 2em 0;}
            .block{
                a{display: inline-block;}
                img{margin: 0 0 0 16px;display: inline-block;vertical-align: top;}
            }
        }
        &#section4{border: none;
            p{margin:0 0 2em 0; text-align: justify;}
        }
    }
}