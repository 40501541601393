@import 'variables.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*GLOBAL*/
#root{ height: 100%; margin: 0; }
ul{margin: 0; padding: 0; list-style: none;}
p{margin: 0;}

.centered{ width: 98%; max-width: 1280px; margin: 0 auto;}
.centered-canvas{ width: 98%; max-width: 90%; margin: 0 auto;}

//to-do & pas d'enfant canvass

/*CARDS*/
.card{width:auto; background: white; border: 1px solid rgba(0,0,0,0.05); border-radius: 10px; padding: 1em; box-shadow: 0 0 8px rgba(0,0,0,0.2); box-sizing: border-box;
  &.content{ border-radius: 0; box-shadow: none; border: 1px solid rgba(0,0,0,0.1); }
  &.interactive{border:1px solid rgba(216,50,92,0.4); box-sizing: border-box;
    &:hover{border-color: $secondary;}
  }
  div {
    .konvajs-content{ margin: auto; }
  }
}

*:focus{outline: none;}

/*DIRECTIVES (intruciton, question, feedback block */
/* .directive-container{
  h3{ margin: 0 0 0.2em 0; color: $secondary; font-size: 18px;}
  p{width: 100%; font-weight: 600; font-size: 18px; line-height: 22px;}
} */

/*BUTTONS*/
.btn{display: block; background: $secondary; border: none; border-radius: 10px; color: white; padding: 0.3em 1em; cursor: pointer; font-weight: 600; font-size: 14px;
  &:focus{outline: none;}
}

.btn-audio-group{display: flex; justify-content: center;
  > button{border-radius: 10px 0 0 10px; border: 1px solid $secondary;
    &:hover{background-color: white; color: $secondary;}
  }
  .audio-container{margin: 0 0 0 2px;
      .btn{border-radius: 0 10px 10px 0; padding: 0.5em 0.5em 0.6em 0.5em; }
  }
}