@import 'variables.scss';

.logout-container{
  .titles-container{ width: 80%; margin:1em auto 0 auto; background: white; border: 1px solid rgba(216, 50, 92, 0.4); border-radius: 10px; padding: 1em; box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); box-sizing: border-box; position: relative;
    h1, h2, h3{text-align: center;}
  }
  h2 { color: grey; margin: 1em; }
  .audio-container{position: absolute; right: 24px; top: 50%; margin: -15px 0 0 0;
    button.btn{margin: 0; position: initial;}
  }
  .student-send-data { margin: 1em; display: flex; justify-content: center; margin-left: 150px;
    h3 { color: grey; margin: 1em; }

    button { display: block; background: $secondary; border: none; border-radius: 10px; color: white; padding: 0.4em 3.5em; cursor: pointer; font-weight: 600; font-size: 18px; 
      margin: 3em 1em; height: fit-content;
      &.disabled { background-color: grey; pointer-events: none; }
      .yes-no-container {
        display: flex; justify-content: center;
      }
      img{width: 70px;}

    }
    .next-button { width: 70px }
  }

  .sync-true { color: #d8325c; }

  .prof-send-data { margin: 1em; display: flex; justify-content: center;
    h3 { color: grey; margin: 1em; }

    button {display: block; background: $secondary; border: 1px solid #d8325c; border-radius: 10px; color: white; padding: 0.4em 3.5em; cursor: pointer; font-weight: 600; font-size: 18px; 
      margin: 3em 1em; height: fit-content;
      &:hover {
        background: white; border: 1px solid #d8325c; color:  #d8325c;;
      }
      img{width: 70px;}
    }
    .sync-true { color: #d8325c; } 
  }
}


