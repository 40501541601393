.dashboard-container { display: flex; justify-content: center; margin: 0 2em 4em 2em; font-family: inherit;
  .title-center-ipad { color: rgba(0, 0, 0, 0.65); text-align: center; font-family: inherit; margin: 0.5em 0px 1em;}
  .col-left { flex-basis: 300px; 
    h2 { color: rgba(0, 0, 0, 0.65); text-align: center; font-family: inherit; margin: 0.5em 0px 1em;}
    .students-container{ border: 1px solid #D8325C; border-radius: 10px; text-align: center; background: white; color: black;
                        height: 608px; overflow-y: auto; -ms-overflow-style: none; 
                        -webkit-scrollbar {display: none;}
      .student-item {height: 40px; border-bottom: 1px solid darkgrey; padding: 0.7em 0em; cursor: pointer; position: relative; font-weight: bold;
        &:hover { background: #D8325C; color: white; font-weight: bold; animation: 0.5s ease fade-in; z-index: 20; }
        img {width: 30px; position: absolute; top: 10px; right: 10px}
      }
      .student-item-selected {height: 40px; background: #D8325C; color: white; font-weight: bold; padding: 0.7em 0em; cursor: pointer; position: relative; font-weight: bold;
        img {width: 30px; position: absolute; top: 10px; right: 10px}
      }
    }
  }
  .col-right { flex-basis: 600px; margin:1.5em 0px 0px ; align-content: center;  transform:translate(0,0);
    .filter-buttons{ display: flex; justify-content: space-around}
    .modules-description{ background: white; border: 1px solid lightgrey; border-radius: 10px; margin: 2em 2em;  padding: 2em 2em; }
    .headlines {display: flex; justify-content: center; margin-bottom: 10px;
      .selected-items {width: 240px; margin: 0 20px; text-align: center; padding: 0.3em 1em;}
    }
  }
  .col-placeholder {flex-basis: auto;}
}


@keyframes fade-in {
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fade-in {
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}



