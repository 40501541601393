@import 'variables.scss';

#logos{display: flex; align-items: center;
    > div{ width: 100px; position: relative;
        img{display: block; width: 100%; height: auto;}
        &#bordas{ padding:0.8em; background: white;}
        &#evib{width: 150px; margin: 0 1em; }
        &#label-header{ background:white; color: $primary; font-weight: 600; padding: 0.5em 1em; text-align: center;}
    }
  }

#main-nav{margin-left: auto;

    .mobile-menu-open, .mobile-menu-close{display: none; }
    .mobile-menu-open{    
        span{ margin: 9px 1em; color: white; font-size: 32px;}
    }
    .mobile-menu-close{
        span{color: #333;}
    }
    .menu-container{
        ul{padding: 0; margin: 0;
            a{ display:block; color: #fff; padding: 15px 16px 14px 16px; font-weight: 600;}
            
            li{
                &.nav-item{ display: inline-block; position: relative; z-index: 10;
                    > a.selected, > a:hover{background: white; color: $secondary;}
                    ul{ width: 100%; display: none; position: absolute; left: 0; top: 49px; border-radius: 0 0 10px 10px; box-shadow: 0 6px 8px rgba(0,0,0,0.2);
                        li{
                            &:last-child a{ border-radius: 0 0 10px 10px;}
                            a{
                                &.selected{color: $secondary;}
                            }
                        }
                    }
                    &:hover{
                        a{background: white; color: #333;}
                        ul{display: block;}
                    }
                    .logout-button { background: #d8325c; height: 50px
                    }
                }
                
            }
        }
    }

}

@media screen and (max-width: 1070px) {

    #main-nav{
        .mobile-menu-open, .mobile-menu-close{display: block;}
        .menu-container{ position: fixed; right: 0; top: 0; z-index: 100; height: 100%; padding: 1em; background: white;box-shadow: 0 6px 8px rgba(0,0,0,0.2); transform: translate3d(300px,0,0); transition: all 0.2s ease-out;
            &.open{ transform: translate3d(0,0,0);}

            ul{
                &.primary{
                    a{color: #333; padding: 1em;}
                
                    li{
                        &.nav-item{ display: block;
                            > a{font-size: 16px;}
                            > a.selected, > a:hover{}
                            ul{ display: block; position: relative; left: auto; top: auto; border-radius: 0; box-shadow: none; padding: 0 0 0 1.5em;
                                li{
                                    &:last-child a{}
                                    a{
                                        &.selected{}
                                    }
                                }
                            }
                            &:hover{
                                a{}
                                ul{}
                            }
                        }
                        
                    }
                }
            }
        }
    }
     
}