.splash-container{display: flex; flex-direction: column; align-items: center;
  .title-container{margin: 0 0 1em 0;}
    h2{ text-align: center; }
    p{ font-size: 16px; margin: 0 0 0.5em 0;}
    ul{
      li{font-size: 16px;}
    }
  .users-container{width: 80%; display: flex; justify-content: center; 
    a{display:block; padding: 0 0.5em; cursor: pointer;
      .user-item{ background: white;
        img{width: 80%; height: auto; display: block; margin: 0 auto;}
        h3{font-weight: bold; text-align: center; margin: 8px;}
      }
      &:first-child{padding-left: 0;}
      &:last-child{padding-right: 0;}
    }
  }
}